import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { useState } from 'react';
import {  Twitter} from 'react-bootstrap-icons';
import { Telegram } from 'react-bootstrap-icons';
import { Github } from 'react-bootstrap-icons';
import hero from "./assets/nfts/6.png"
import icon from "./assets/icon.png"
import nft from "./assets/nfts/1.png"
import nft2 from "./assets/nfts/2.png"
import nft3 from "./assets/nfts/3.png"
import pinksale from "./assets/pinksale.png"
import dexscreener from "./assets/dexscreener.png"
import coinmarketcap from "./assets/coinmarketcap.png"
import coingecko from "./assets/coingecko.webp"
import Team1 from "./assets/team.jpeg"
import Team2 from "./assets/team2.jpg"
import Team3 from "./assets/team3.jpeg"





function App() {
  const [page,setPage] = useState(0);
  const [selectedValue, setSelectedValue] = useState('0');  // State to keep track of the selected value
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [play,setPlay] = useState(0);
  const handleChange = (event) => {
      setSelectedValue(event.target.value);  // Update the state when the selected value changes
  }

  const handleSend = () => {
    if (input.trim()) {
      // Add user message
      setPlay(1);
      setMessages(prev => [...prev, { text: input, sender: 'user' }]);
      
      // Automatically add the response after the user's message
      setMessages(prev => [...prev, { text: "Hello, User we are happy that you are trying our AI. We will be in service soon. Stay connected with XGoku.", sender: 'bot' }]);

      setInput('');
    }
  };
  return (
  <>
  <br /> <br />
  <div className='container'>
 <div className='row'>
  <div className='col-sm-4' style={{borderLeft:"5px solid orange"}}>
     <h1 className='fw-bold pointable' onClick={() => setPage(0)}>HOME</h1>
  
     <h1 className='fw-bold pointable' onClick={() => setPage(1)}>ABOUT</h1>
     <h1 className='fw-bold pointable' onClick={() => setPage(7)}>GAMEFI</h1>  
     <h1 className='fw-bold pointable' onClick={() => setPage(2)}>NFT MINT</h1>
     <h1 className='fw-bold pointable' onClick={() => setPage(8)}>NFT MARKETPLACE</h1>
     <h1 className='fw-bold pointable' onClick={() => setPage(9)}>GoKuX Ai-Chat</h1>
     <h1 className='fw-bold pointable' onClick={() => setPage(3)}>PARTNERS</h1>
     <h1 className='fw-bold pointable' onClick={() => setPage(4)}>TOKENOMICS</h1>
     <h1 className='fw-bold pointable' onClick={() => setPage(10)}>ROADMAP</h1>
     <h1 className='fw-bold pointable' onClick={() => setPage(5)}>TEAM</h1>
     <h1 className='fw-bold pointable' onClick={() => setPage(6)}>AUDIT</h1>
     <h1><button className='btn btn-warning btn-lg'>BUY ON PINKSALE</button></h1>
  </div>

  <div className='col-sm-8'>
    {page === 0? <>
      <h1 style={{fontSize:"120px",textShadow: "2px 2px orange"}}>SAIYAN NEVER DIE</h1>
    <h3 style={{color:"white",textShadow: "1px 1px white"}}>Goku is a meme token hosted on the ETHEREUM Network, serving as the foundational cryptocurrency for an NFT marketplace. Goku is a premier memeToken dedicated to offering top-notch services to its community. Acquire our exclusive NFTs using GokuMemeToken, and immerse yourself in our gaming experience powered by GokuMemeToken.</h3>
    <button className='btn btn-warning btn-lg'>WHITEPAPER</button> <button className='btn btn-warning btn-lg'>BUY ON PINKSALE</button>
 
    </> : <></>}

    {page === 1? <>
      <h1 style={{fontSize:"120px",textShadow: "2px 2px orange"}}>ABOUT</h1>
    <h3 style={{color:"white",textShadow: "1px 1px white"}}>Goku is a crypto token inspired by Dragon Ball Z. It is a deflationary token with a limited supply, making it a valuable investment for fans of the series and cryptocurrency enthusiasts alike</h3>
    <h2 className='text-warning fw-bold'>New Features</h2>
    <p className='text-white'>You can use GoKuX in our Game</p>
    <p className='text-white'>Purchase our NFT's with our Token</p>
    <p className='text-white'>Anti-Bot (Protected By FrontRunners bots)</p>
    <p className='text-white'>Stable and low fees</p>
    <button className='btn btn-warning btn-lg'><Telegram /> TELEGRAM</button>     <button className='btn btn-warning btn-lg'><Github /> GITHUB</button>     <button className='btn btn-warning btn-lg'><Twitter /> TWITTER</button>     <button className='btn btn-warning btn-lg'>CONTRACT</button>
   
    </> : <></>}

    {page === 2? <>
      <h1 style={{fontSize:"120px",textShadow: "2px 2px orange"}}>MINT NFTS</h1>
      <div style={{background:"orange", height:"20px"}} /> <br />
      <div className='row'>
        <div className='col-sm-6'>
          <img src={hero} alt='hero' className='img-fluid img-thumbnail' />
        </div>
        <div className='col-sm-6'> <br />
        <h3 style={{color:"white",textShadow: "1px 1px white"}} className='alert alert-warning text-dark fw-bold'>MINT GoKuX NFT's</h3>
 <table className='table text-white'>
  <tr>
    <td><h1 className='bg-dark text-white fw-bold'>Price</h1></td>
    <td><h1 className='bg-dark text-white fw-bold'>0.2 BNB</h1></td>
  </tr>
  <tr>
    <td><h1 className='bg-dark text-white fw-bold'>You Get</h1></td>
    <td><h1 className='bg-dark text-white fw-bold'>0.0 BNB</h1></td>
  </tr>
 </table>
    <input type='number' placeholder="NFT's AMOUNT" className='form-control'  /> <br />
    <button className='btn btn-warning btn-lg'>MINT</button> <button className='btn btn-warning btn-lg'>See NFTS</button>
 
        </div>
      </div>
   
    </> : <></>}

    {page === 3? <>
      <h1 style={{fontSize:"120px",textShadow: "2px 2px orange"}}>PARTNERS</h1>
    <h2 style={{color:"white",textShadow: "1px 1px white"}} className=' fw-bold'>Our listing Partners</h2>
    <div className='row'>
      <div className='col-sm-6'>
        <img src={pinksale} alt="pinksale" width={200} className='img-fluid img-thumbnail' />
      </div>
      <div className='col-sm-6'> <img src={dexscreener} width={200} alt="dexscreener" className='img-fluid img-thumbnail' /></div>
      </div>
      <br />
      <div className='row'>
      <div className='col-sm-6'> <img src={coinmarketcap} width={200} alt="coinmarketcap" className='img-fluid img-thumbnail' /></div>
      <div className='col-sm-6'> <img src={coingecko} width={200} alt="coingecko" className='img-fluid img-thumbnail' /></div>
    </div>
  
    </> : <></>}

    {page === 4? <>
      <h1 style={{fontSize:"120px",textShadow: "2px 2px orange"}}>TOKENNOMICS</h1>
  <h1 className='text-white'>Pre-Sale: 66% <br /> Liquadity: 33%  <br /> Team: 0.2% <br /> Game Play bot: 0.8% </h1>
  <h1>BUY and SELL each 5% Fee in Native Coin</h1>
    </> : <></>}

    {page === 5? <>
      <h1 style={{fontSize:"120px",textShadow: "2px 2px orange"}}>TEAM</h1>
      <br />
    <h3 style={{color:"white",textShadow: "1px 1px white"}} className='text-center'>Our Team</h3>
<br />
<div className='container'>
  <div className='row'>
  <div className='col-sm-4'>
    <img src={Team1} alt="Team" width={300} className='img-fluid rounded'/>
    <h2 className='text-center text-white fw-bold'>Woltz</h2>
    <h3 className='text-center text-white fw-bold'>Founder and Dev</h3>
   <center><button className='btn btn-primary fw-bold'><Telegram /></button></center> 
  </div>
  <div className='col-sm-4'>
    <img src={Team2} alt="Team" width={300} className='img-fluid rounded'/>
    <h2 className='text-center text-white fw-bold'>Crystle Ninja</h2>
    <h2 className='text-center text-white fw-bold'>Developer</h2>
    <center><button className='btn btn-primary fw-bold'><Telegram /></button></center> 
  </div>
  <div className='col-sm-4'>
    <img src={Team3} alt="Team" width={300} className='img-fluid rounded'/>
    <h2 className='text-center text-white fw-bold'>Kinsley</h2>
    <h2 className='text-center text-white fw-bold'>Marketing Manager</h2>
    <center><button className='btn btn-primary fw-bold'><Telegram /></button></center> 
  </div>
  </div>
</div>
    </> : <></>}

    {page === 9? <>
      <h1 style={{fontSize:"100px",textShadow: "2px 2px orange"}}>XGOKU-AI-CHAT</h1>
      <div className="card" style={{height:"50%"}}>
        <div className='card-body bg-dark'>
      <div className="messages">
        {play === 0? <><center><h1 className='text-white fw-bold'>Welcome to XGoku-AI CHAT</h1></center></> : <>
        {messages.map((msg, index) => (
          <div key={index} className={msg.sender}>
             <span className="icon">{msg.sender === 'user' ? "👤" : "🤖"}</span>
            {msg.text}
          </div>
        ))}
        </>}
       
      </div>
      </div>
     <br />
     <div className='container'>
       <div className='row'>
        <div className='col-sm-10'>
        <input 
          value={input} 
          className='form-control'
          onChange={e => setInput(e.target.value)}
          onKeyPress={e => e.key === 'Enter' && handleSend()}
        />
          </div>
        
        <div className='col-sm-2'>
        <button onClick={handleSend} className='btn btn-success'>Send</button>
        </div>
        </div>
        </div>
       <br />
    
    </div>
    </> : <></>}

    {page === 6? <>
      <h1 style={{fontSize:"120px",textShadow: "2px 2px orange"}}>AUDIT</h1>
    <h3 style={{color:"white",textShadow: "1px 1px white"}}>Coming Soon</h3>
    </> : <></>}

    {page === 10? <>
      <h1 style={{fontSize:"120px",textShadow: "2px 2px orange"}}>ROADMAP</h1>
      <div className='row'>
        <div className='row'>

        <div className='col-sm-4'>
          <div className='card bg-dark'>
            <div className='card-header bg-warning'>
            <h1 className='text-white fw-bold'>
              2023 - SEP
            </h1>
           
            </div>
            <div className='card-body bg-dark'>
             <p className='text-white fw-bold'>Coinmarketcap fast Track listing</p>
            <p className='text-white fw-bold'>Exchanges listing</p>
            <p className='text-white fw-bold'>CoinGecko Listing</p>
          </div>
            </div>
          </div>
           


          <div className='col-sm-4'>
            <div className='card bg-dark'>
              <div className='card-header bg-warning'>
              <h1 className='text-white fw-bold'>
              2023 - OCT
            </h1>
              </div>
              <div className='card-body'>
              <p className='text-white fw-bold'>MINT NFT LAUNCH</p>
            <p className='text-white fw-bold'>NFT MARKETPLACE LAUNCH</p>
            <p className='text-white fw-bold'>GAMEFI LAUNCH</p>
            <p className='text-white fw-bold'>XCHAT AI LAUNCH</p>
              </div>
            </div>
          
           
          </div>

          <div className='col-sm-4'>
            <div className='card bg-dark'>
              <div className='card-header bg-warning'>
              <h1 className='text-white fw-bold'>
              2023 - Dec
            </h1>
              </div>
              <div className='card-body bg-dark'>
              <p className='text-white fw-bold'>MOBILE APPLICATION LAUNCH</p>
            <p className='text-white fw-bold'>STAKING PROTOCOL LAUNCH</p>
            <p className='text-white fw-bold'>OUR DECENTRALIZED EXCHANGE LAUNCH</p>
              </div>
            </div>
         
         
          </div>
        </div>
      </div>
    </> : <></>}


    {page === 7? <>
      <h1 style={{textShadow: "2px 2px orange"}}>GAMEFI (MVP)</h1> 
      <br /> <br />
   <div className='container'>
    <div className='card'>
      <div className='card-body bg-warning'>
        <h3 className='text-danger badge bg-warning fw-bold'>Balance: 700 XGOKU</h3>

      <div className='row'>
      <div className='col-sm-4'>
        <div className='card bg-dark'>  <div className='card-body bg-dark'>  <img src={nft} alt="home" className='img-fluid img-thumbnail bg-dark ' />
      <h3 className='text-white text-center fw-bold'>0x123...7A</h3>
      <h2 className='text-danger text-center fw-bold'>POWER: XP07</h2>
      </div>
      </div>  
      </div>

      <div className='col-sm-4'>
      <center><h1 style={{fontSize:"130px"}}>VS</h1> <br /><span className='text-danger'>BID: 200 XGOKU </span></center>
      </div>

      <div className='col-sm-4'>
      <div className='card bg-dark'>  <div className='card-body bg-dark'> 
      <img src={nft2} alt="home" className='img-fluid img-thumbnail bg-dark ' />
      <h3 className='text-white text-center fw-bold'>0x123...B2</h3>
      <h2 className='text-danger text-center fw-bold'>POWER: XP12</h2>
      </div>
      </div>
      </div>
    </div>
    <div className='col-sm-12'>
     <center> <button className='btn btn-warning btn-lg'>PLAY</button></center>
    </div>
      </div>
    </div>
   
   </div>
    </> : <></>}


    {page === 8? <>
      <h1 style={{fontSize:"120px",textShadow: "2px 2px orange"}}>MARKETPLACE</h1>
      <h2 className='fw-bold text-white text-center'>MVP VERSION</h2>
      <p className='text-white text-center'>Our Marketplace Demo Version</p>
      <select className='control-form bg-dark text-white' value={selectedValue} onChange={handleChange} style={{width:"100%",height:"40px"}}>
        <option value="0">All</option>
        <option value="1">Common</option>
        <option value="2">Rare</option>
        <option value="3">Legendary</option>
      </select>
      <br /> <br />
     <div className='row'>
      {selectedValue === '0'? <>
        <div className='col-sm-4'>
        <div className='card bg-dark text-white placebid image-container'>
          <div className='card-body bg-dark'>
            <img src={nft} alt="home" className='img-fluid img-thumbnail bg-dark ' />
            <h3 className='fw-bold text-center'>Common Goku</h3>
            <hr />
            <h5><img src={icon} alt='icon' width={30} className='img-fluid' /> <button className="button-hidden">BID</button> Posted By: YomyI</h5>
            <hr />
            <div className='row'>
              <div className='col-sm-6'>Current Bid</div>
              <div className='col-sm-6'>536 XGoku</div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-sm-4'>
        <div className='card bg-dark text-white placebid image-container' >
          <div className='card-body bg-dark'>
            <img src={nft2} alt="home" className='img-fluid img-thumbnail bg-dark' />
            <h3 className='fw-bold text-center'>Rare Goku</h3>
            <hr />
            <h5><img src={icon} alt='icon' width={30} className='img-fluid' /> <button className="button-hidden">BID</button> Posted By: XeXhz</h5>
            <hr />
            <div className='row'>
              <div className='col-sm-6'>Current Bid</div>
              <div className='col-sm-6'>1436 XGoku</div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-sm-4'>
        <div className='card bg-dark text-white placebid image-container'>
          <div className='card-body bg-dark'>
            <img src={nft3} alt="home" className='img-fluid img-thumbnail bg-dark' />
            <h3 className='fw-bold text-center'>Legendary Goku</h3>
            <hr />
            <h5><img src={icon} alt='icon' width={30} className='img-fluid' /> <button className="button-hidden">BID</button> Posted By: Nhinge</h5>
            <hr />
            <div className='row'>
              <div className='col-sm-6'>Current Bid</div>
              <div className='col-sm-6'>3706 XGoku</div>
            </div>
          </div>
        </div>
      </div>
      </> : selectedValue === '1'? <>    <div className='col-sm-4'>
        <div className='card bg-dark text-white placebid image-container'>
          <div className='card-body bg-dark'>
            <img src={nft} alt="home" className='img-fluid img-thumbnail bg-dark ' />
            <h3 className='fw-bold text-center'>Common Goku</h3>
            <hr />
            <h5><img src={icon} alt='icon' width={30} className='img-fluid' /> <button className="button-hidden">BID</button> Posted By: YomyI</h5>
            <hr />
            <div className='row'>
              <div className='col-sm-6'>Current Bid</div>
              <div className='col-sm-6'>536 XGoku</div>
            </div>
          </div>
        </div>
      </div></> : selectedValue === '2'? <>    <div className='col-sm-4'>
        <div className='card bg-dark text-white placebid image-container' >
          <div className='card-body bg-dark'>
            <img src={nft2} alt="home" className='img-fluid img-thumbnail bg-dark' />
            <h3 className='fw-bold text-center'>Rare Goku</h3>
            <hr />
            <h5><img src={icon} alt='icon' width={30} className='img-fluid' /> <button className="button-hidden">BID</button> Posted By: XeXhz</h5>
            <hr />
            <div className='row'>
              <div className='col-sm-6'>Current Bid</div>
              <div className='col-sm-6'>1436 XGoku</div>
            </div>
          </div>
        </div>
      </div></> : selectedValue === '3'? <>
      <div className='col-sm-4'>
        <div className='card bg-dark text-white placebid image-container'>
          <div className='card-body bg-dark'>
            <img src={nft3} alt="home" className='img-fluid img-thumbnail bg-dark' />
            <h3 className='fw-bold text-center'>Legendary Goku</h3>
            <hr />
            <h5><img src={icon} alt='icon' width={30} className='img-fluid' /> <button className="button-hidden">BID</button> Posted By: Nhinge</h5>
            <hr />
            <div className='row'>
              <div className='col-sm-6'>Current Bid</div>
              <div className='col-sm-6'>3706 XGoku</div>
            </div>
          </div>
        </div>
      </div>
      </> : <></>}
   
     </div>
    </> : <></>}
      </div>
 </div>
 <br />
 <center>
<h2 className='text-warning fw-bold'><Twitter />TELEGRAM - <Github /> GITHUB -  <Twitter /> TWITTER</h2>
 </center>
 </div>
  </>
  );
}

export default App;
